import React from "react";
import {createTheme, ThemeProvider} from '@mui/material/styles';

import Home from "./Home";

const theme = createTheme({
    palette: {
        primary: {
            main: "#192A55",
            whiter: "#FFFFFF"
        },
        secondary: {
            main: "#F04A5A",
        },
    },
    typography: {
        fontFamily: 'Montserrat'
    }
});

// const myEffect = keyframes`
//   0% {
//     opacity: 0;
//     transform: translateY(-200%);
//   }
//   100% {
//     opacity: 1;
//     transform: translateY(100%);
//   }
// `;
// const myEffectExit = keyframes`
//   0% {
//     opacity: 1;
//     transform: translateY(100%);
//   }
//   100% {
//     opacity: 1;
//     transform: translateY(-200%);
//   }
// `;



function Index({location}) {
    return (<ThemeProvider theme={theme}>
        <Home location={location}/>
    </ThemeProvider>);
}

export default Index;

export { Head } from "../components/Head"