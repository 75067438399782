import React from "react";

export const Head = () => {
    return (
        <>
            <title>Insight Technology | Unlock Your Healthcare Potential with Insight Technology</title>
            <meta name="description" content="Insight Technology is a leading healthcare technology services and consulting firm that specializes in providing innovative solutions to healthcare providers, payers, and patients. With decades of experience in the healthcare industry, our team of experts has a deep understanding of the challenges and opportunities facing healthcare organizations today." />
        </>
    )
  };
